import React from 'react'
import { Col, Image, Row } from 'react-bootstrap';
import Img from "./assets/images/showroom.jpg";
import { Link } from 'react-router-dom/cjs/react-router-dom';

const VisitShowroom = () => {
  return (
    <section className='rcs_visit_showroom'>
      <div className='inner-wrap'>
        <div className='image'>
            <Image src={Img} alt='visit our showroom' />
        </div>

        <div className='content'>
            <h5 className='mb-3'>Visit Our Store</h5>
            <p className='mb-4'> Our showroom in Chicago, Illinois, boasts an extensive range of jewelry, from dazzling diamond engagement rings to elegantly crafted fine jewelry. Whether you're searching for the perfect symbol of love or simply treating yourself to something special, our collection has something to suit every taste and occasion. Additionally, our skilled artisans offer expert jewelry repair services, ensuring that your beloved pieces are restored to their former glory. Visit European Jewelers today and experience luxury like never before!</p>
            <Link to="/make-an-appointment" className="btn outline-base-btn">Book an Appointment</Link>
        </div>
      </div>
    </section>
  )
}

export default VisitShowroom