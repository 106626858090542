import React from 'react'
import RingBuilderImg from "./assets/images/repair_img.jpg"
import Mobile from "./assets/images/repair_img_mobile.jpg"
import { Col, Image, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom/cjs/react-router-dom'
import Container from './Container';

const RepairSection = () => {
  return (
    <>
      <Image src={Mobile} alt='' className='pb-0 d-md-none d-block' />
      <div className='repair-section' style={{background: `url(${RingBuilderImg}) no-repeat center top/cover`}}>
        <Container>
            <Row>
                <Col lg={5} md={7} className='text-md-start text-center'>
                    <h6 className='pb-2 m-0'>Jewelry & Watch Repair Services</h6>
                    <p className='mt-2 mb-4'>European Jewelry is a trusted name when it comes to Jewelry and watch repair services in Chicago. We cover all the major luxury watch brands, and our team is comprised of highly experienced professionals who can restore your precious pieces with the utmost care and attention. Contact us to learn how we can restore your precious pieces.</p>
                    <Link to = "/services" className="btn outline-base-btn">Explore Now</Link>
                </Col>
            </Row>
        </Container>
      </div>
    </>
  )
}

export default RepairSection