import React from 'react'
import RingBuilderImg from "./assets/images/create-diamond.jpg"
import RingBuilderImgMobile from "./assets/images/create-diamond-mobile.jpg"
import { Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom/cjs/react-router-dom'
import { GoArrowRight } from "react-icons/go";
import Container from './Container';
import { isMobile } from 'react-device-detect'

const RingBuilder = () => {
  return (
    <div className='ring-builder-section' style={{background: `url(${isMobile ? RingBuilderImgMobile : RingBuilderImg}) no-repeat center/cover`}}>
        <Container>
            <Row>
                <Col lg={7} md={12} className='text-md-start text-center'>
                    <h2 className='pb-2'>Create your own <span className='mt-2'>Diamond Ring</span></h2>
                    <p className='mt-2 mb-0'>Ready to design your engagement ring? Use our custom diamond ring builder! Choose from different settings and diamonds to create a ring that reflects your style and story. Visit our nearby jewelry store for even more options.</p>
                    <div className='button-flex mt-4'>
                        <Link to = "/engagement-rings/settings" className="btn outline-base-btn">Start With A Setting <GoArrowRight /></Link>
                        <span className='d-inline-block px-3'>Or</span>
                        <Link to = "/naturaldiamonds" className="btn outline-base-btn">Start With A Diamond <GoArrowRight /></Link>
                    </div>
                </Col>
            </Row>
        </Container>
    </div>
  )
}

export default RingBuilder