import React from 'react';
import Left from "./assets/images/left.jpg"
import Right from "./assets/images/right.jpg"
import { Col, Image, Row } from 'react-bootstrap';

const ImageSection = () => {
  return (
    <section className='rcs_image_section overflow-hidden pt-0'>
        <Row>
            <Col lg={6} md={6} className='mb-md-0 mb-3'>
                <Image src={Left} alt='ring' />
            </Col>
            
            <Col lg={6} md={6}>
                <Image src={Right} alt='necklace' />
            </Col>
        </Row>
    </section>
  )
}

export default ImageSection